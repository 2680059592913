* {
	margin: 0;
	padding: 0;
}

html,body {
    height:100%;
    padding:0;
    margin:0;
}

html {
	-webkit-font-smoothing: antialiased;
}

body {
	font-size: 12px;
	font-family: 'Roboto', sans-serif;
	height: 100%;
}

iframe#webpack-dev-server-client-overlay{display:none!important}

.sr-only {
    display: none;
}

.fullgrid {
	display: grid;
	grid-template-rows: auto 1fr auto;
	grid-template-areas: "header" "wrappergrid" "footer";
	gap: 0px 0px;
	height: 100vh;
}

.wrappergrid {
	grid-area: wrappergrid;
	overflow: auto;
}

header {
	grid-area: header;
}

footer {
	grid-area: footer;
}

h1, h2, h3, h4 {
	font-family: 'Lato', sans-serif;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

a, .linkText {
	text-decoration: none;
	cursor: pointer;
}

.linkText {
	color: #e84a5f;
}

#tooltip {
	position:absolute;
	background:#e84a5f;
	color:#ffffff;
	padding:12px;
	z-index:999;
	max-width: 300px;
}

#tooltip.alt-tooltip {
	background:#490B22;
}

.wrapper {
	width: 100%;
	min-height: calc(100vh - 269px);
	display: block;
	background-image: -webkit-linear-gradient(bottom, #d5d4d0 0%, #d5d4d0 1%, #eeeeec 31%, #efeeec 75%, #e9e9e7 100%);
	background-image: -o-linear-gradient(bottom, #d5d4d0 0%, #d5d4d0 1%, #eeeeec 31%, #efeeec 75%, #e9e9e7 100%);
	background-image: linear-gradient(to top, #d5d4d0 0%, #d5d4d0 1%, #eeeeec 31%, #efeeec 75%, #e9e9e7 100%);
}

header.cabezote {
	width: 100%;
	border-bottom: 1px solid #c3c3c3;
	display: flex;
	justify-content: space-between;
	background-color: #efeeec;
}

.cabezoteLeft {
	padding: 15px 0;
}

.cabezoteRight {
	display: inline-block;
}

.cabezoteRight h2 {
	display: inline-block;
	font-size: 2em;
	font-family: 'Lato', sans-serif;
	color: #fff;
	transform: translateY(50%);
	-webkit-transform: translateY(50%);
	padding: 0 8px;
}

nav {
	display: inline-block;
	cursor: pointer;
	color: #5a5050;
	font-size: 14px;
	vertical-align: top;
}

nav a {
	color: #232323;
	transition: color 0.3s ease;
}
nav a:hover {
  color: tomato;
}

#menuToggle {
  display: block;
  position: relative;
  top: 18px;
  left: 14px;
  z-index: 3;
  -webkit-user-select: none;
  user-select: none;
  border-right: 1px solid #c3c3c3;
  padding-right: 20px;
  margin-right: 15px;
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0;
  z-index: 4;
  -webkit-touch-callout: none;
}

#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #335b6b;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #e84a5f;
}

#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

#menu {
  position: absolute;
  width: 230px;
  margin: -100px 0 0 -50px;
  padding: 112px 30px 30px 55px;
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  background: #fff;
  border-bottom-right-radius: 5px;
  box-shadow: 0 3px 3px rgba(0,0,0,0.12), 0 3px 3px rgba(0,0,0,0.14);
}

#menu li {
  padding: 10px 0;
  font-size: 20px;
}

#menuToggle input:checked ~ ul {
  transform: none;
}

header h3 {
	font-weight: 400;
	height: 55px;
	padding: 10px 10px 0;
	margin-left: 5px;
	font-size: 36px;
	color: #e84a5f;
	line-height: 1;
	display: inline-block;
}

header h3 a {
	color: #e84a5f;
}

.interna {
	display: inline-block;
	padding: 5px 10px 0;
	resize: both;
}

.interna h3 {
	padding: 0;
	margin: 0;
	height: auto;
	font-size: 32px;
  color: #e84a5f;
}

.interna h5 {
	font-size: 18px;
	line-height: 0;
	padding: 12px 0 0 1px;
	color: #5a5050;
	font-weight: 400;
}

.userBox {
	display: inline-block;
    position: relative;
	padding: 4px 10px 0;
	resize: both;
	color: #fff;
}

.userBox:hover {
	color: #2a363b;
}

.userInfo {
	display: inline-block;
	position: relative;
	padding: 4px 10px 4px 15px;
	resize: both;
	color: #c3c3c3;
}

.userInfo h6 {
	font-size: 16px;
	vertical-align: top;
	font-weight: 600;
	line-height: 0;
	display: inline-block;
	height: 22px;
	line-height: 22px;
	padding: 15px 0 15px 15px;
	color: #2a363b;
}

.iconUserLogged {
  background: url(images/logout.png) no-repeat center;
  float: right;
  width: 20px;
  height: 22px;
  margin-top: 18px;
  border: none;
  outline: none;
  cursor: pointer;
}

header h4 {
	font-weight: 300;
	height: 60px;
	padding: 7px 0;
	margin-right: 30px;
	font-size: 15px;
	color: #6f6f6f;
	line-height: 1.1;
	text-align: right;
}

header h4 b {
	font-weight: 500;
}

.finderline {
	width: 100%;
	min-height: 400px;
	background: url(images/sky.jpg) no-repeat center center;
	background-size: cover;
	background-color: #2a363b;
	padding: 20px 0;
	text-align: center;
}

h1 {
	color: #2a363b;
	font-size: 30px;
	font-weight: 300;
	margin-top: 30px;
	line-height: 1;
	text-align: center;
}

.maint {
	text-transform: uppercase;
	margin-bottom: 35px;
	margin-top: 5px;
}

h2 {
	color: #6f6f6f;
	font-size: 22px;
	font-weight: 300;
	margin-bottom: 10px;
	font-family: 'Roboto', sans-serif;
	text-align: center;
}

.finder {
	width: 280px;
	height: 280px;
	margin: 35px 15px;
	display: inline-block;
	background: url(images/indicadores.png) no-repeat center center;
	font-size: 30px;
	line-height: 1;
	text-transform: uppercase;
	color: transparent;
	font-weight: 300;
	font-family: 'Lato', sans-serif;
	border: 2px solid transparent;
	transition: all 1s ease;
}

.finder b {
	font-weight: 400;
}

.geo {
	background-image: url(images/geografico.png);
}

.ind {
	background-image: url(images/indicadores.png);
}

.per {
	background-image: url(images/relaciones.png);
}

.com {
	background-image: url(images/compensaciones.png);
}

.port {
  background-image: url(images/portafolios.png);
}

.ale {
	background-image: url(images/alertas.png);
}

.mon {
	background-image: url(images/mcomunitario.png);
}

.activeicon {
	border: 2px solid #fff;
	border-radius: 10px;
}

.finder:hover {
	color: #2a363b;
	background: none;
	background-color: #fff;
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	border-radius: 10px;
}

.menuline {
	width: 100%;
	min-height: 100px;
	background: -webkit-linear-gradient(#e84a5f 0%, #e84a5f 140px, transparent 140px, transparent 100%);
	background: -o-linear-gradient(#e84a5f 0%, #e84a5f 140px, transparent 140px, transparent 100%);
	background: linear-gradient(#e84a5f 0%, #e84a5f 140px, transparent 140px, transparent 100%);
	padding: 20px 0;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: center;
}

menu {
	display: inline-block;
	margin: 35px;
	margin-left: 0;
}

menu button {
	text-align: left;
	display: block;
	width: 318px;
	border: none;
	outline: none;
	background-color: #fff;
	cursor: pointer;
	padding: 25px 30px;
	border-bottom: 1px solid #e6e6e6;
	font-size: 15px;
	color: #5a5050
}

menu button b {
	border: 1px solid #5a5050;
	padding: 3px;
	margin-right: 2px;
}

menu button.active:hover b,
menu button.active b {
	border: 2px solid #fff;
	padding: 5px;
}

menu button:hover b {
	border: 1px solid #fff;
}

menu button:hover {
	background-color: #547a82;
	color: #fff;
}

menu button.active {
	padding: 35px 30px;
	font-size: 20px;
	background-color: #2a363b;
	color:#fff;
	border-bottom: none;
}

.showHome {
	min-width: 28px;
	min-height: 28px;
	background: none;
	background-image: url(images/downinfo.png);
	border: none;
	top: -14px;
	left: 85px;
	z-index: 2;
	position: relative;
}

.showHome:focus {
	outline: none !important;
}

.rotate {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
    -webkit-transition: all 1s;
	-moz-transition: all 1s;
	-ms-transition: all 1s;
	-o-transition: all 1s;
	transition: all 1s;
}

.hidden-false p,
.hidden-true p {
	max-width: 300px;
	font-family: 'Lato', sans-serif;
	font-size: 14px;
}

.rotate-false {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
    -webkit-transition: all 1s;
		-moz-transition: all 1s;
		-ms-transition: all 1s;
		-o-transition: all 1s;
		transition: all 1s;
}

.hidden-true {
	padding: 0;
	height: 0;
	overflow: hidden;
	-webkit-transition: all 1s;
	-moz-transition: all 1s;
	-ms-transition: all 1s;
	-o-transition: all 1s;
	transition: all 1s;
}

.hidden-false {
	padding: 30px 0 35px 15px;
	background-color: #fff;
	z-index: 1;
	height: auto;
	-webkit-transition: all 1s;
	-moz-transition: all 1s;
	-ms-transition: all 1s;
	-o-transition: all 1s;
	transition: all 1s;
}

.hidden {
	display: none;
}

.invisible {
	display: none;
	transition: all 1s ease;
}

.geocont {
	display: block;
}

.content {
	display: inline-block;
	width: 700px;
	vertical-align: top;
	margin-top: 80px;
	margin-left: 30px;
	display: none;
}

.content h1 {
	text-align: left;
	margin-bottom: 50px;
	color: #fff;
}

.content p {
	line-height: 1.5;
	font-size: 16px;
	color: #2a363b;
	padding-bottom: 30px;
}

.content {
	display: block;
}

.col1 {
	width: 70%;
	display: inline-block;
	vertical-align: top;
}

.col1 h2 {
	text-align: left;
	padding: 20px 0 0 20px;
}

.col1 img {
	margin-left:7px;
}

.col1 img:first-of-type {
	margin-left: 30px;
}

.chart {
	padding: 20px;
}
.col2 {
	width: calc(30% - 20px);
	min-height: calc(100vh - 272px);
	display: inline-block;
	margin-left: 10px;
	padding: 0;
	border-left: 1px solid #c1c1c1;
	vertical-align: top;
}

.col2 h1 {
	text-align: left;
	margin:0 0 10px 0;
	background-color: #e84a5f;
	color: #fff;
	padding: 35px;
	width: 100%;
	vertical-align: top;
	width: calc(100% - 64px);
}

.col2 h3 {
	font-weight: 400;
	font-size: 16px;
	padding: 0 35px;
	color: #355c7d;
}

.col2 p {
	padding: 10px 30px 10px 50px;
	font-size: 14px;
	color: #5a5050;
}

.col2 img {
	margin: 10px 0 15px 35px;
}

.download {
	border: none;
	padding: 10px 13px 10px 37px;
	background: url(images/down1.png) #fff 5px no-repeat;
	color: #e84a5f;
	font-size: 14px;
	margin: 0 35px 20px;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.download:hover {
	background-color: #2a363b;
	background-image: url(images/down2.png);
	color: #fff;
}

.mb10 {
	margin-bottom: 5px;
}

.mb30 {
	margin-top: 30px;
	max-height: 700px;
	overflow-y: scroll;
}

.contgeo {
	padding: 20px;
}

.minicol {
	display: inline-block;
	width: 23%;
	margin-left: 1.5%;
	vertical-align: top;
}

.minicol h1 {
	font-size: 19px;
	text-transform: uppercase;
	padding: 15px;
	background-color: #2a363b;
	color: #fff;
	font-weight: 400;
}

.minicard {
	border: 1px solid #5a5050;
	padding: 10px 8px 15px 12px;
	margin: 15px auto;
	width: 85%;
	transition: all 0.7s cubic-bezier(.25,.8,.25,1);
}

.fivedots {
	min-height: 100px !important;
}

.botones {
	float: right;
	vertical-align: top;
	border-left: 1px solid #c1c1c1;
	padding-left: 7px;
	margin-left: 5px;
}

.botones button {
	height: 15px;
	width: 15px;
	background: none;
	border: none;
	background-color: #f67280;
	border-radius: 50%;
	cursor: pointer;
	display: block;
	margin-top: 3px;
}

button.rel2{
	background-color: #6c5b7b;
}

button.rel3{
	background-color: #e82e47;
}

button.rel4{
	background-color: #2b74cf;
}

button.rel5{
	background-color: #ed8425;
}

button.rel6{
	background-color: #e3b21b;
}

button.rel7{
	background-color: #7c9c37;
}

button.rel8{
	background-color: #3eaca8;
}

.rela1active {
	background-color: #fff;
	border: none;
	border-top: 6px solid #f67280;
}
.rela2active {
	background-color: #fff;
	border: none;
	border-top: 6px solid #6c5b7b;
}
.rela3active {
	background-color: #fff;
	border: none;
	border-top: 6px solid #e82e47;
}
.rela4active {
	background-color: #fff;
	border: none;
	border-top: 6px solid #2b74cf;
}
.rela5active {
	background-color: #fff;
	border: none;
	border-top: 6px solid #ed8425;
}
.rela6active {
	background-color: #fff;
	border: none;
	border-top: 6px solid #e3b21b;
}
.rela7active {
	background-color: #fff;
	border: none;
	border-top: 6px solid #7c9c37;
}
.rela8active {
	background-color: #fff;
	border: none;
	border-top: 6px solid #3eaca8;
}

.relgraph {
	display: none;
	margin: 0 auto;
	text-align: center;
	width: 900px;
}

.relgraph h2, .relgraph h3 {
	text-align: left;
	font-weight: 400;
	line-height: 1;
	color: #2a363b;
}

.relgraph h3 {
	font-size: 18px;
}

.backbtn {
	position: absolute;
	top:135px;
	left: 30px;
	border: 1px solid #e84a5f;
	color: #e84a5f;
	font-size: 16px;
	padding: 3px 8px;
	display: none;
}

.backbtn:hover {
	background-color: #e84a5f;
	color: #fff;
	border: none;
}

.minicard:hover {
	background-color: #fff;
	border-color:#fff;
}

.minicard h3 {
	text-align: left;
	font-weight: 400;
	font-size: 18px;
	color: #2a363b;
	display: block;
	margin-bottom: 18px;
}

.minicard a {
	font-size: 18px;
	color: #e84a5f;
	border-radius: 50%;
	padding: 0 7px;
	border: 1px solid #e84a5f;
	vertical-align: top;
	line-height: 8px;
}


.minicard a:hover {
	background-color: #e84a5f;
	color: #fff;
}

.relgraph {
	padding: 30px;
}

footer {
	width: 100%;
	background-color: #22231c;
	z-index: 20;
	padding: 12px 20px 8px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

footer h4 {
	text-align: right;
	padding-bottom: 15px;
	display: block;
	color: #6c6c6c;
	font-weight: 600;
	line-height: 1;
	font-size: 13px;
}
.footerflex {
	padding: 10px 0;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
}

.colaboradores a {
	margin-left: 30px;
	vertical-align: top;
}

.footersm {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
}

.footersm a {
	line-height: 15px;
	vertical-align: baseline;
	color: #fff;
	font-weight: 400;
	font-size: 15px;
}

.footersm h3 {
	text-align: right;
	line-height: 18px;
	vertical-align: top;
}

.footersm h3 button{
	cursor: pointer;
}

footer h3 a, .footersm h3 a {
	color: #e84a5f;
	font-size: 15px;
}

.footerTooltip {
	border: none;
	background: none;
	color: #e84a5f;
	font-family: 'Lato', sans-serif;
	font-size: 15px;
}

.quoteStyle {
	width: 130px;
}

/* isotope */


	#options-wrap.affix {
	  top: 51px;
	  width: 100%;
	}
	#options-wrap {
	  z-index: 999999;
	}
	#options {
	  width: 100%;
	  padding-top: 20px;
	}
	#filter-container {
		margin: 0 auto;
		position: relative;
		overflow: hidden;
		height: 2162px;
		width: 909px;
	}
	#filters .item {
	  margin: 20px;
	  float: left;
	  width: 250px;
	  min-height: 220px;
	  background-color: #fff;
	  padding: 20px;
	  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  	  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
	}

	#filters .item:hover {
	  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
	  background-color: #fecea8;
	}

	#filters p {
	  margin: 0;
	}
	#filters #filter-display,
	#filter-counter {
	  font-size: 16px;
	  padding: 10px;
	  line-height: 2;
	}
	div.filter-display-container {
		display: block;
	}

	.tab-pane {
	  padding-top: 20px;
	}
	/* Start: Recommended Isotope styles */
	/**** Isotope Filtering ****/
	.isotope-item {
	  z-index: 2;
	}
	.isotope-hidden.isotope-item {
	  pointer-events: none;
	  z-index: 1;
	}
	/**** Isotope CSS3 transitions ****/
	.isotope,
	.isotope .isotope-item {
	  -webkit-transition-duration: 0.1s;
	     -moz-transition-duration: 0.1s;
	       -o-transition-duration: 0.1s;
	          transition-duration: 0.1s;
	}
	.isotope {
	  -webkit-transition-property: height, width;
	     -moz-transition-property: height, width;
	       -o-transition-property: height, width;
	          transition-property: height, width;
	}
	.isotope .isotope-item {
	  -webkit-transition-property: -webkit-transform, opacity;
	     -moz-transition-property:    -moz-transform, opacity;
	       -o-transition-property:         top, left, opacity;
	          transition-property:         transform, opacity;
	}
	/**** disabling Isotope CSS3 transitions ****/
	.isotope.no-transition,
	.isotope.no-transition .isotope-item,
	.isotope .isotope-item.no-transition {
	  -webkit-transition-duration: 0s;
	     -moz-transition-duration: 0s;
	       -o-transition-duration: 0s;
	          transition-duration: 0s;
	}
	/* End: Recommended Isotope styles */
	a.metas:hover, label.metas:hover,
	a.perb:hover, label.perb:hover,
	a.ecospp:hover, label.ecospp:hover
	{cursor: hand !important; cursor: pointer !important;}
	a.metas, a.perb, a.ecospp, a.clone {
		padding: 5px;
  	}
  	label.metas, label.perb, label.ecospp
  	{margin-top: 10px; margin-bottom: 10px;}
  	a.disabled,
  	a.disabled:hover,
  	a.disabled:active {
  		color: #A9A9A9;
  	}
  	.data-counter {
      padding-right: 200px;
    }
  	#reset-filters:hover {
  		cursor: hand !important;
  		cursor: pointer !important;
  	}

.item h1 {
	text-align: left;
	border-bottom: 1px solid #424242;
	padding-bottom: 15px;
}

.item h1:hover {
	color:#e84a5f;
}

.item h2 {
	text-align: left;
	font-size: 18px;
	font-style: italic;
	padding-top: 5px;
}

.item h3 {
	font-weight: 400;
	color: #3eaca8;
	padding-bottom: 30px;
	padding-top: 10px;
}

.item a {
	font-size: 30px;
	color: #e84a5f;
	border-radius: 50%;
	border: 1px solid #e84a5f;
	float: right;
	height: 46px;
	width: 46px;
	text-align: center;
	line-height: 46px;
}

.item a:hover {
	background-color: #e84a5f;
	color: #fff;
	transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

#filters {
	padding-bottom: 50px;
}

.filter-display-container {
	font-size: 15px;
	color: #2a363b;
	display: block;
	background-color: #547a82;
	padding: 20px;
}

.filter-display-container a.metas,
.filter-display-container a.perb,
.filter-display-container a.ecospp {
	padding: 20px 20px 30px 0;
	padding-bottom: 20px;
	color: #fff;
}

a#reset-filters {
	color: #22231c;
	border: 1px solid #22231c;
	padding: 3px 8px;
}

#options {
	padding-bottom: 45px;
	background-color: #2a363b;
}

#options h1 {
	color: #fff;
	text-align: left;
	border-bottom: 1px solid #fff;
	display: inline-block;
	padding: 5px 0;
	margin: 0 30px 0;
}

#options > div {
	padding: 5px;
}

#options h5 {
	padding: 20px 0 10px;
	display: block;
	font-size: 20px;
	font-weight: 300;
	color: #3eaca8;
}

#options label {
	font-size: 14px;
	color: #fff;
	letter-spacing: 1;
	font-weight: 400;
}

#options .disabled label {
	color: #547a82;
	font-weight: 300;
}

.appSearcher {
  background-color: #eeeeec;
  display: -webkit-flex;
  display:         flex;
  -webkit-flex-flow: row;
          flex-flow: row;
}

.drawPAcc {
	width: 100%;
}

.drawPAcc .MuiAccordionSummary-content svg {
	width: 20px;
	height: 20px;
	color: #e84a5f;
}

.confirmButton {
  position: absolute;
  top: 143px;
  left:10px;
  background-color: #e84a5f;
  color: #501e23;
  z-index: 401;
  display: block;
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  padding: 5px;
  border: none;
}

.confirmButton svg {
	width: 19px;
	height: 19px;
}

.confirmActions {
  position: absolute;
  font-weight: 400;
  top: 143px;
  left: 42px;
  z-index: 401;
  box-sizing: border-box;
}

.confirmActions li {
  list-style: none;
  display: inline-block;
}

.confirmActions li button {
  background-color: #e84a5f;
  color: #fff;
  border: none;
  height: 30px;
  width: 100%;
  border-left: 1px solid #AAA;
  padding: 0 10px;
  font-size: 12px;
  cursor: pointer;
}

.mapsTitle {
	position: relative;
	left: 45px;
	font-size: 15px;
	z-index: 401;
	display: inline-block;
}

.mapsTitle > .title {
  background-color: #fff;
  color: #e84a5f;
	border-bottom: 1px solid #e84a5f;
  padding: 5px 12px;
}

.mapsTitle > .legendError {
  background-color: #fff;
  text-align: center;
  color: #424242;
}

.gradientLegend {
  background-color: #fff;
  font-size: 9px;
  font-weight: 600;
  color: #2a363b;
  margin-top: 1px;
  padding: 5px 8px 3px;
}

.gradientLegend > .text {
  display: flex;
  justify-content: space-between;
  margin-top: 0 auto;
}

/*Map container*/
.viewfinder {
  margin: 2px;
  padding: 5px;
  border: 0px solid #cccc33;
  background-color: #eeeeec;
  -webkit-flex: 1 1 60%;
          flex: 1 1 60%;
 -webkit-order: 1;
         order: 1;
}
/*All information container*/
.contentView {
overflow: auto;
	-webkit-flex: 1 1 40%;
	flex: 1 1 40%;
	flex-direction: column;
	-webkit-order: 2;
	order: 2;
	z-index:2;
}

.contentView svg {
	transition: all 1s ease;
}

.selector {
	font-family: 'Roboto', sans-serif;
	font-size: 12px;
}

.selector h1 {
  padding: 20px;
  background-color: #e84a5f;
  color: #fff;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 2.2em;
  text-align: left;
  margin: 0;
}

.drawer_header {
	display: flex;
	align-items: center;
	background-color: #e84a5f;
}

.header_info {
	background-color: #e84a5f;
	padding: 15px;
	text-align: right;
	margin-right: 25px;
	display: grid;
    grid-auto-flow: column;
    align-self: center;
}

.header_info h1 {
  font-size: 1.2em;
	font-weight: 400;
	color: #fff;
  text-align: right;
	margin: 0;
	padding: 18px;
	display: inline-block;
	vertical-align: middle;
}

.header_info h1 b {
	font-size: 1.3em;
}

.Consultasgeográficas {
  background-image: url(images/geografico.png);
  -webkit-background-size: contain;
  background-size: contain;
  float: right;
  width: 65px;
  height: 65px;
}

.Indicadores {
	background-image: url(images/indicadores.png);
	-webkit-background-size: contain;
	background-size: contain;
	float: right;
	width: 65px;
	height: 65px;
}

.Portafolios {
	background-image: url(images/portafolios.png);
	-webkit-background-size: contain;
	background-size: contain;
	float: right;
	width: 65px;
	height: 65px;
}

.Compensaciónambiental {
  background-image: url(images/compensaciones.png);
  -webkit-background-size: contain;
  background-size: contain;
  float: right;
  width: 65px;
  height: 65px;
}

.Monitoreocomunitario {
  background-image: url(images/mcomunitario.png);
  -webkit-background-size: contain;
  background-size: contain;
  float: right;
  width: 65px;
  height: 65px;
}

.mt2 {
  margin-top: 35px;
}

.selector p {
    line-height: 1.5;
    font-size: 15px;
    color: #2a363b;
    padding: 0 25px;
    max-width: 75%;
    margin-top: 12px;
}

.selector p i {
  font-style: normal;
  padding: 0 5px;
  margin-right: 3px;
  border: 1px solid #2a363b;
  color: #2a363b;
}

.informer h3 {
	padding: 0;
  margin: 0;
  text-align: center;
	font-weight: bold;
	height: auto;
	font-size: 12px;
  color: #4a585f;
}

.description {
  margin-bottom: 15px;
}

/*Belongs to contentView*/

.drawer {
  background-color: #eeeeec;
  font-family: 'Open Sans', sans-serif;
	font-size: 12px;
	padding: 5px 8px 0;
  -webkit-flex: 3 1 60%;
          flex: 3 1 60%;
 -webkit-order: 2;
         order: 2;
}

.drawer h3 {
	padding: 0;
  margin: 0;
  text-align: center;
	font-weight: bold;
	height: auto;
	font-size: 12px;
  color: #4a585f;
}

#region {
    display: block !important;
    padding-top: 0;
}


.m0, .m0b {
    margin: 0 !important;
    color: #2a363b !important;
	font-size: 14px;
}

.m0b > div:nth-child(1) {
	background-color: #a1b6bf;
}

.m1 {
	color: #4a585f;
	display: inline-block;
	width: 100%;
	background-color: #ebebeb !important;
}

.m0b div[class*="MuiExpansionPanelDetails-root"] {
	padding: 6px 8px !important;
}

.bulletTooltip {
  max-width: 230px;
  white-space: normal;
  line-height: 1;
}

.extraLegend {
	font-size: 12px;
	display: flex;
	justify-content: space-between;
	font-weight: 600;
	padding: 0 30px 0 15px;
	margin-top: -8px;
	color: #424242;
}

.accordionSelected {
	background-color: #fff !important;
}

.accordionSelected > div:nth-child(1) {
	color: #e84a5f;
	text-transform: uppercase;
	font-size: 18px;
	letter-spacing: 1;
	border-bottom:1px solid #e84a5f;
}


.leaflet-container {
	height: auto;
	padding: 5px;
	border: 0px solid #cccc33;
	background-color: #eeeeec;
	-webkit-flex: 3 1 60%;
					flex: 3 1 60%;
 	-webkit-order: 1;
				 order: 1;
  	font-family: 'Roboto', sans-serif !important;
  	z-index: 0;
}

.leaflet-control-zoom a {
	background-color: #e84a5f !important;
	color: #fff !important;
	font-weight: 300 !important;
	border-radius: 0 !important;
}

.leaflet-draw a {
	background-color: #e84a5f !important;
	color: #fff !important;
	background-blend-mode: multiply;
	font-weight: 400 !important;
	border-radius: 0 !important;
}

.leaflet-bar {
  border: 0 !important;
}

.selector-expanded > div:nth-child(1),
#panel1-Geocerca > div:nth-child(1),
#draw-polygon > div:nth-child(1),
#panel3 > div:nth-child(1) {
  font-size: 15px;
  background-color: #2a363b;
  color: #fff !important;
  font-family: 'Open Sans', sans-serif !important;
}

#panel1-Geocerca > div:nth-child(1) svg,
.selector-expanded > div:nth-child(1) svg,
#draw-polygon > div:nth-child(1) svg,
#panel3 > div:nth-child(1) svg {
  color: #e84a5f;
}

.disabled {
  background-color: #000;
}

.MuiFormLabel-root.Mui-focused, .tabs.Mui-selected {
	color: #e84a5f !important;
}

.DrawerTab .MuiTabs-indicator {
	background-color: #e84a5f !important;
}

.MuiAutocomplete-option[data-focus="true"] {
	background-color: rgba(232, 74, 95, 0.04) !important;
}

.MuiInputBase-root{
	background-color: #fff;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiInput-underline::after{
	border-color: #2a363b !important;
}

div[class*="hjhLld"] {
	font-size: 14px !important;
  font-weight: bold;
	line-height: normal !important;
}

div[class*="eaRHYv"] {
	font-size: 14px !important;
	line-height: normal;
}

.inlineb {
  display: inline-block !important;
	margin: 10px 20px;
}

#region .inlineb button {
  background-color: transparent;
  border: none;
  border-right: 1px solid #2a363b;
  padding: 5px 12px;
  font-size: 13px;
  font-weight: 300;
  cursor: pointer;
  outline: none;
  width: 120px;
  min-height: 42px;
  vertical-align: top;
  font-weight: 600;
  color: #979797;
  margin-bottom: 3px;
}

#region .inlineb button:hover {
  border-right: 1px solid transparent;
  color: #fff;
  background-color: #e84a5f;
}

.Select-menu-outer {
	top: auto;
	bottom: 100%;
}

.complist-longer .Select-menu-outer {
	position: relative !important;
	height: auto !important;
	display: block !important;
}

.Select-option.is-focused  {
    background-color: rgba(252, 228, 232) !important;
}


/*2do menu graficas*/

.geobtn, .tittlebtn {
  border: none;
  background-color: transparent;
  color: #fff;
  outline: none;
  cursor: pointer;
  border-bottom: 1px solid transparent;
}

.tittlebtn {
  display: inline-block;
}

.geobtn svg {
  font-size: 30px;
}

.geobtn:hover {
  color: #2a363b;
  border-bottom: 1px solid #2a363b;
}

.tittlebtn:hover {
  border: none;
}

.tabs {
  width: 25% !important;
  vertical-align: top !important;
  margin: 0 !important;
  padding: 0 !important;
}

.tabs2 {
  width: 50% !important;
  max-width: auto !important;
}

.informer header{
  box-shadow: none !important;
  height: auto;
  padding: 0;
}

.HAgen h4 {
	padding: 8px 25px;
	text-transform: uppercase;
	font-size: 14px;
	background-color: #2a363b;
	font-weight: 400;
	color: #e84a5f;
}

.HAgen h4 b {
	text-transform: none;
	margin-left: 10px;
	font-weight: 700;
	color: #fff;
}

.graphcontainer {
  margin: 0;
  padding: 15px !important;
}

.pt5 {
	padding-top: 0 !important;
}

.pt6 {
	width: 98%;
	padding: 15px 0 0 !important;
}

.graphcontainer h4 {
	display: inline-block;
	position: relative;
	z-index: 1;
	text-transform: uppercase;
	font-size: 18px;
	font-weight: 400;
	color: #e84a5f;
	padding: 8px;
	border: 1px solid #e84a5f;
	margin-top: 5px;
	background-color: #fff;
}

.graphcontainer h4 b  {
	font-weight: 600;
	color: #2a363b;
	margin-left: 10px;
	text-transform: none;
}

.graphcontainer h5 {
	padding: 2px 4px;
	font-weight: 600;
	color: #fff;
	background-color: #e84a5f;
	margin: -7px 0 0 10px;
	text-transform: none;
	font-size: 15px;
	display: table;
	position: relative;
	z-index: 2;
}

.graphcontainer h6 {
	font-size: 14px;
	margin-left: 10px;
	font-weight: 400;
	margin-top: 10px;
	padding-bottom: 3px;
	border-bottom: 1px solid #2a363b;
	color: #2a363b;
	display: table;
}

.disclaimer {
	font-size: 12px;
  color: #424242;
  margin: 0 30px 10px 15px;
  border-top: 1px solid #5db1d4;
  border-bottom: 1px solid #5db1d4;
  padding: 7px 6px 3px;
}

.fullview-container {
  padding-bottom: 6px;
  display: inline-block;
  margin-left: 15px;
  border-bottom: 2px solid transparent;
}

.fullview {
  width: 40px;
  vertical-align: bottom;
}

.fullview-container:hover {
  border-bottom: 2px solid #000000;
  cursor: pointer;
}

.fullview-container.filtered {
  border-bottom: 2px solid tomato;
}

.singleeco {
	font-size: 16px;
	margin-left: 5px;
	font-weight: 400;
	padding: 5px 7px 4px;
	background-color: #2a363b;
	color: #fff;
	display: inline-block;
}

.singleeco2 {
	font-size: 16px;
	font-weight: 600;
	color: #2a363b;
	border: 1px solid #2a363b;
	padding: 4px 6px 3px;
	display: inline-block;
}

.graphcontainer h3 {
	font-size: 14px;
	font-weight: 500;
	padding: 3px 5px;
	color: #2a363b;
	margin: 0 0 5px 7px;
	text-align: left;
}

.graphcontainer h3 b {
	font-weight: 700;
	margin-left: 10px;
}

.ecoest {
	margin: 25px 12px 10px;
	border: 1px solid #ea495f;
	padding: 0 15px;
}

.minus20 {
	margin-top: -20px !important;
	margin-left: -27px !important;
}

.minusperc {
	margin-left: -17px !important;
}

.graficaeco {
	max-width: 600px;
 line-height: 1em !important;
}

.graficaeco2 {
	margin: 0 0 12px 5px;
	max-width: 593px;
}

.graficaeco2 svg {
	margin: 0 !important;
	line-height: 1em !important;
}

.graficaeco2 h3 {
	margin-bottom: 0;
	padding: 0;
}

.informer header button{
  max-width: 500px !important;
}

.tooltip-graph-container {
	background-color: #333;
	color: #ffffff;
	padding: 5px 10px;
	line-height: 1.5;
	border-radius: 5px;
	min-width: 60px;
	box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
  font-size: 12px;
  white-space: nowrap;
}

.graphcard {
  margin: 10px 0;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  background-color: #fff;
  position: relative;
}

.graphcard:hover {
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.graphcard h2 {
  border-bottom: 1px solid #e84a5f;
  padding: 10px;
  font-size: 16px;
  display: block;
  text-align: left;
  color: #fff;
  font-weight: 400;
  background-color: #2a363b;
}

.graphcard p {
  padding: 10px;
  text-align: right;
  font-size: 14px;
  color: #424242;
}

.graphinfo {
  display: inline-block;
  cursor: pointer;
  margin-top: -3px;
	border-style: none;
	vertical-align: middle;
	padding-right: 3px;
	outline: none;
}

.graphcardAcc .graphinfo {
	padding-left: 0px;
	padding-right: 0;
	color: #bbbbbb;
}

.graphinfobox {
	padding-left: 10px;
	margin-bottom: 20px;
}

.MuiSvgIcon-root-1.graphinfo {
	font-size: initial;
}

.graphinfo:hover {
	color: #e84a5f;
}

.graphinfo2-true p,
.graphinfo3-true p {
	font-family: 'Lato', sans-serif;
	color: #424242;
	padding: 3px 8px 10px 0;
	text-align: left;
	font-size: 12px;
  line-height: 1.3;
}

.pt5 .graphinfo3-true p {
	padding-top: 13px;
}

.graphinfo2-true,
.graphinfo3-true {
	-webkit-transition: all 1s;
	-moz-transition: all 1s;
	-ms-transition: all 1s;
	-o-transition: all 1s;
	transition: all 1s;
	border-bottom: 1px solid #dddddd;
	width: 94%;
	margin: 0 0 15px 20px;
}

.graphinfo2-true {
	margin-left: 10px;
}

.pt6 h2 svg,
.pt5 h3 svg,
.pt6 h3 svg {
	color: #bbbbbb;
}

.pt6 h2 svg:hover,
.pt5 h3 svg:hover,
.pt6 h3 svg:hover,
.downSpecial:hover,
.downSpecial2:hover  {
	color: #e84a5f;
}

.pt6 .graphinfo {
	display: block;
	text-align: left;
	padding-left: 10px;
	margin-bottom: 10px;
	cursor: pointer;
}

.pt6 .graphinfo3,
.pt5 .graphinfo3 {
	display: inline-block;
	margin-right: 10px;
	margin-bottom: 5px;
	cursor: pointer;
}

.graphinfo.activeBox,
.graphinfo3.activeBox,
.downSpecial.activeBox,
.downSpecial2.activeBox {
  color: #e84a5f;
}

.pt6 p {
	margin: 5px 0 0 15px;
}

.ml10 .graphinfo2-true {
	margin-left: 20px;
}

.ml10 .graphinfobox .graphinfo2-true {
	margin-left: 10px;
}

.svgPointer, .svgPointer2 {
	cursor: pointer;
}

.graphcardAcc {
	box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
	margin-bottom: 5px;
	padding-bottom: 10px;
}

.graphcardAcc h2 {
	color: #e84a5f;
	text-transform: uppercase;
	font-size: 18px;
	letter-spacing: 1;
	border-bottom:1px solid #e84a5f;
	font-weight: 400;
	padding: 22px 24px;
	text-align: left;
	margin-bottom: 10px;
}

.graphcardAcc h3, .pt6 h6 {
	display: inline-block;
	padding: 5px 12px;
	text-align: left;
	font-size: 14px;
	margin: 12px 3px 8px 15px;
	letter-spacing: 1;
	text-transform: uppercase;
	border: 1px solid #4a585f;
	color: #4a585f;
}

.graphcardAcc h3.textBoxes {
	border: none;
	display: block;
  padding: 3px 5px;
  margin: 0 0 5px 22px;
}

.pt6 h6 {
	margin-right: 0;
}

.pt6 h6.h6Selected {
	color: #ffffff;
	background-color: #120000;
	font-weight: 600;
}

.svgPointer2 h6 {
	margin-top: 0;
	margin-bottom: 3px;
	min-width: 100px;
}

.svgPointer2 h5 {
	min-width: 40px;
}

.svgPointer2 .p-label {
	margin-bottom: 5px;
  margin-left: 15px;
	text-transform: uppercase;
	font-weight: 600;
	cursor: initial;
}

.pt6 h5 {
	display: inline-block;
	margin-left: 0;
	padding: 5px 12px;
	border: 1px solid;
}

.numberSP {
  display: flex;
  justify-content:space-between;
  align-items: center;
}

.nos-title {
  margin-left: 30px;
  line-height: 1.2;
  color: #6f6f6f;
}

.nos-title b {
  color: tomato;
}

.nos-title.legend {
	margin-left: 15px;
	margin-bottom: 15px;
}

.nos-title.selected {
  color: #2a363b;
  font-weight: 600;
  margin-left: 15px;
  border-left: 2px solid tomato;
  padding-left: 14px;
}

.numberSP a {
  margin-right: 10px;
}

.numberSP a:hover {
  color: tomato;
}

.richnessLegend {
  margin: 10px 0 10px 15px;
}

.richnessLegend p {
  margin: 0;
  margin-bottom: 2px;
}

.dpcLegend {
	margin-bottom: 10px;
}

.dpcLegend p {
	margin-right: 10px;
}

.targetsLegend {
  margin: 10px 0 10px 25px;
}

.targetsLegend p {
  margin: 0;
  margin-left: 5px;
}

.targetsLegendInfoButton{
	color: #bbbbbb;
	text-align: left;
	padding-left: 0px !important;
}

.targetsLegendInfoButton svg{
	font-size: 12px;
}

.innerInfo {
	text-transform: none !important;
	border: none !important;
	font-weight: 700 !important;
}

.innerInfoH5 {
	padding: 3px 4px 0 !important;
}

.innerInfoH3 {
	margin: 2px 0 0 35px !important;
}

.fiLegend {
	margin-bottom: 10px;
}

p.legcomp {
  padding-top: 0;
}

.legcomp b {
  color: #ea495f;
  margin-right: 10px;
  margin-left: 10px;
}
.legcomp i {
  font-style: normal;
  font-weight: 600;
  color: #eabc47;
  margin-right: 10px;
}
.legcomp em {
  font-style: normal;
  font-weight: 600;
  color: #51b4c1;
  margin-right: 10px;
}

.pb {
  padding-bottom: 0;
}

.leaflet-popup-tip {
  background-color: rgba(0,0,0,0.9) !important;
}
.leaflet-popup-content-wrapper {
  background-color: rgba(0,0,0,0.9) !important;
  color: #fff !important;
  padding: 12;
  line-height: 1.5;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
}
.leaflet-tooltip {
  background-color: rgba(0,0,0,0.9) !important;
  color: #fff !important;
  padding: 12;
  line-height: 1.5;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  border: 0;
}

.nocolor {
  color: transparent;
}

.total {
  padding: 10px 15px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  background-color: #fff;
  border-radius: 5px;
  margin-right: 5px;
  min-width: 190px;
  display: inline-block;
  margin-bottom: 5px;
}

.total h3 {
  font-size: 18px;
  color: #ea495f;
  font-weight: 400;
  padding-bottom: 5px;
  text-align: left;
}

.total:hover {
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.total h4 {
  font-size: 30px;
  text-align: left;
}

.ecosystems {
  margin-top: 15px;
}

.Biomatit {
  color: #424242;
  padding: 5px;
  border-bottom: 1px solid #ea495f;
}

.complist-longer {
	margin-top: 5px;
}

.popbtns {
	padding-bottom: 10px;
	/* display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between; */
}

.complist-longer svg {
  color: #ea495f;
  float: left;
  width: 25px;
  height: 25px;
}

.graphcardAcc .icondown {
	cursor: pointer;
	color: #2a363b;
	float: right;
}

.icondown-container {
	display: inline-block;
	vertical-align: middle;
}

.icondown-container svg {
	color: #bbbbbb;
}

.icondown-container svg:hover {
	color: #e84a5f;
}

.icongraph, .icondelete {
	color: #fff;
	cursor: pointer;
	border: 0;
	background-color: transparent;
	text-align: right;
	display: inline-block;
	outline: none;
}

.icongraph2 {
	color: #e84a5f;
	background-color: transparent;
	cursor: pointer;
	border: 0;
	text-align: left;
	display: inline-block;
	outline: none;
	vertical-align: bottom;
}

.icondelete {
	float: right;
}

.icondelete:disabled {
	color: #757474;
	outline: 0;
}

table.graphcard {
  width: 100%;
  font-size: 12px;
  padding: 7px;
}

.graphcard th {
  color: #ea495f;
  border-bottom: 1px solid #ea495f;
  text-transform: uppercase;
  font-size: 13px;
  padding: 5px 3px;
  margin-bottom: 15px;
  text-align: left;
}

.graphcard td {
  color: #345b6b;
  padding: 1px 3px;
  margin-bottom: 15px;
  text-align: left;
}

tr.row2table:nth-child(odd){
  background-color: rgba(234, 73, 95, 0.15);
}

.graphcard tr.row3table td {
  background-color: #345b6b;
  font-weight: 600;
  color: #fff !important;
}

.addbiome {
  outline: none;
  background: transparent;
  cursor: pointer;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: relative;
  border: 2px solid #ea495f;
  margin-top: 10px;
}

.addbiome::after {
  content: " ";
  position: absolute;
  display: block;
  background-color: #ea495f;
  height: 4px;
  margin-top: -2px;
  top: 50%;
  left: 5px;
  right: 5px;
  z-index: 9;
}

.addbiome::before {
  content: " ";
  position: absolute;
  display: block;
  background-color: #ea495f;
  width: 4px;
  margin-left: -2px;
  left: 50%;
  top: 5px;
  bottom: 5px;
  z-index: 9;
}

.addbiome:hover {
  background-color: #ea495f;
}

.addbiome:hover::after,
.addbiome:hover::before {
  background-color: #fff;
}

.subbiome {
  outline: none;
  background: transparent;
  cursor: pointer;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: relative;
  border: 2px solid #345b6b;
  margin-top: 10px;
}

.subbiome::after {
  content: " ";
  position: absolute;
  display: block;
  background-color: #345b6b;
  height: 4px;
  margin-top: -2px;
  top: 50%;
  left: 5px;
  right: 5px;
  z-index: 9;
}

.subbiome:hover {
  background-color: #345b6b;
}

.subbiome:hover::after {
  background-color: #fff;
}

.smbtn {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-top: 1px;
}

.smbtn::after {
  height: 2px;
  margin-top: -1px;
  left: 3px;
  right: 3px;
}

.smbtn::before {
  width: 2px;
  margin-left: -1px;
  top: 3px;
  bottom: 3px;

}

.tableError {
  position: absolute;
  background-color: #c2374d;
  color: #fff;
  padding: 3px 10px;
  float: left;
  z-index: 200;
  margin-top:82px;
  margin-left: -10px;
  font-weight: 600;
  letter-spacing: 1px;
}

.inputError {
  color: #c2374d;
  font-weight: 600;
}

.areaCompleted {
  color: #62591e;
}

.errorData {
  padding: 10px 0px 10px 25px;
  font-size: 14px;
  display: block;
  text-align: left;
  color: #cf5a16;
  font-weight: 400;
  background-color: #fff;
  margin-bottom: 5px;
  margin-top: 5px;
}

.graphcard td input {
  width: 75px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ea495f;
}

.graphcard td input:focus {
  outline: none;
  border-color: #000;
}

.special .row2table td:nth-child(3) {
  white-space: nowrap;
}

.special {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
	margin-top: -9px;
}

.overview {
  background-color: #2a363b;
  color: #fff;
	padding: 10px 10px 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.titecositema {
  margin-top: 8px;
  background-color: #2a363b;
  padding: 10px 10px 15px;
  color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.listSS {
	margin-top: 10px;
	background: #fff;
	color: #e84a5f;
	border-radius: 3px;
}

.HasSelected {
	background: #fff;
	color: #e84a5f;
	white-space: nowrap;
	padding: 5px 8px;
	height: 22px !important;
	margin-left: 10px;
	text-align: right;
	font-size: 18px;
	border-radius: 3px;
	font-weight: 500;
	margin-bottom: 5px;
}

.addedBioma {
	color: #51b4c1;
}

.backgraph, .downgraph, .saveStrategyButton {
  color: #2a363b;
  vertical-align: middle;
  text-align: left;
  font-weight: 600 !important;
  font-size: 13px !important;
  border: 1px solid #fff !important;
  border-radius: 3px;
  background-color: transparent !important;
  outline: none;
  cursor: pointer;
  line-height: 20px !important;
	text-transform: uppercase;
	padding: 5px 8px !important;
	min-height: 32px !important;
	min-width: 164px !important;
}

.downgraph {
	margin-top: 10px !important;
}
.saveStrategyButton {
min-width: initial !important;
}

.downgraph svg, .backgraph svg, .iconsave svg, .saveStrategyButton svg {
	height: 20px;
	width: 20px;
	margin-top: 0;
	margin-right: 3px;
	color: #ea495f;
	float: left;
}

.backgraph:hover, .downgraph:hover, .saveStrategyButton:hover{
	background-color: #fff !important;
}

tspan, .vx-axis-label {
  font-stretch: always !important;
  border: none !important;
  font-weight: 300 !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.loginBtnCont {
	display: grid;
	min-height: 70px;
	margin-top: -6px;
}

.loginBtn {
	border: none;
	background-color: transparent;
	outline: none;
	cursor: pointer;
}

.rightTitle {
	text-align: right;
	font-size: 11px;
	color: #424242;
	font-weight: 700;
	padding-right: 70px;
	margin-bottom: -20px;
	text-transform: uppercase;
}

.divergentDataBar {
	font-size: 13px;
	margin-left: 10px;
	color: #cf5a16;
}

.newProjectTitle {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
	padding: 15px 20px 10px;
	background-color: #e84a5f;
	border-radius: 5px 0 0 0;
}

.newProjectTitle h2 {
	color: #fff;
	text-transform: uppercase;
	vertical-align: baseline;
	line-height: 25px;
}

.generalAlarm {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(66, 66, 66, .6);
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: center;
}

.generalAlarm h2 {
	padding: 75px 30px 0;
	width: 260px;
	height: 260px;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, 1);
	box-sizing: border-box;
}

.generalAlarm h2 b {
	text-transform: uppercase;
	color: #ea485f;
}

.generalAlarm button {
	color: #ea485f;
	position: fixed;
	top:20px;
	right: 20px;
}

.uim_modal {
	position: fixed;
	width: 300px;
	background-color: #ffffff;
	box-shadow: 0 3px 3px rgba(0,0,0,0.12), 0 3px 3px rgba(0,0,0,0.14);
	top: 85px;
	right: 25px;
	border-radius: 5px 0 0 5px;
	padding: 140px 20px 30px;
}

.uim_modal.loginModal {
	background-image: url(images/login.png);
	/*background-image: url(images/bgmountain.png);*/
	background-repeat: no-repeat;
}

.uim_modal.userModal {
	background-image: url(images/LogoGEB.png);
	background-position: 50% 20%;
	background-repeat: no-repeat;
}

.uim_modal:hover {
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.login input {
	padding: 0 10px;
	box-sizing: border-box;
	outline: none;
	font-size: 12px;
	width: 100%;
	margin-top: 10px;
}

.login input::placeholder {
	color: #aaa;
}

.loginbtn, .logoutbtn {
	margin-top: 30px;
	outline: none;
	cursor: pointer;
	color: #e84a5f;
	text-align: right;
	text-decoration: underline;
	background-color: transparent;
	border: none;
	float: right;
	font-size: 12px;
}

.uim_modal .closebtn {
	color: #ea485f;
	position: relative;
}

.loginbtn.disabled {
	color: #345b6b;
	text-decoration: none;
}

.recoverbtn {
	margin-top: 30px;
	outline: none;
	cursor: pointer;
	color: #345b6b;
	text-align: left;
	background-color: transparent;
	border: none;
	float: left;
	font-size: 12px;
}

.loginInput {
	line-height: 34px;
	border-radius: 4px;
	background-color: #ffffff;
	border: 1px solid #ccc;
	margin-top: 1px;
	color: #333333;
	position: relative;
	width: 99%;
}

.newProjectModal .npcontent  {
	padding: 15px;
}

.newProjectModal {
	position: fixed;
	width: 350px;
	background-color: #ffffff;
	box-shadow: 0 3px 3px rgba(0,0,0,0.12), 0 3px 3px rgba(0,0,0,0.14);
	bottom: 200px;
	right: 0;
	outline: none;
	border-radius: 5px 0 0 5px;
	padding-bottom: 15px;
}

.newProjectModal:hover {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.addprjbtn {
	margin-top: 10px;
	outline: none;
	cursor: pointer;
	color: #e84a5f;
	text-align: right;
	background-color: transparent;
	border: none;
	float: right;
}

.closebtn {
	background-color: transparent;
	border: none;
	outline: none;
	line-height: 25px;
	cursor: pointer;
}

.closebtn:hover {
	color: #fff;
}

.projectInput {
	line-height: 34px;
	border-radius: 4px;
	background-color: #ffffff;
	border: 1px solid #ccc;
	margin-top: 1px;
	color: #333333;
	position: relative;
	width: 99%;
}

.newBiome .npcontent  {
	padding: 0 15px 10px;
}

.newBiome h2 {
  font-size: 18px;
  color: #ea495f;
  font-weight: 400;
  padding: 10px 15px 0;
  text-align: left;
  text-transform: uppercase;
  margin-top: 5px;
}

.newBiome {
	width: auto;
	background-color: #ffffff;
	border-radius: 5px;
	box-shadow: 0 1px 3px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.newBiome:hover {
  	box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.newBiome .addprjbtn {
	float: none;
	margin: 0;
	margin-left: 90%;
	padding-top: 10px;
}

.sendCreateBiomes {
	border: none;
	background-color: transparent;
	color: #e84a5f;
	outline: none;
	cursor: pointer;
	border: 1px solid #e84a5f;
	padding: 5px 10px;
	text-transform: uppercase;
	margin-top: 5px;
	font-size: 12px;
	letter-spacing: 1px;
	font-weight: 600;
}

.sendCreateBiomes:hover, .newBiomeAlarm button:hover {
	color: #fff;
	background: none;
	background-color: #e84a5f;
}

.newBiomeAlarm {
	padding: 75px 30px 0;
	width: 260px;
	height: 260px;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, 1);
	box-sizing: border-box;
	text-align: center;
	font-size: 14px;
	margin-top:calc(50vh - 130px);
	margin-left:calc(50vw - 130px);
	outline: none;
	color: #424242;
}

.newBiomeAlarm div {
	border-bottom: 1px solid #424242;
	padding-bottom: 10px;
	margin-bottom: 10px;
}

.newBiomeAlarm button {
	border: none;
	background-color: transparent;
	color: #e84a5f;
	outline: none;
	cursor: pointer;
	border: 1px solid #e84a5f;
	padding: 5px 0;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 600;
	width: 50px;
	margin: 5px;
}

.nBA2 {
	padding-top: 100px;
}

.ul-padding {
	padding-left: 15px;
}

.ul-padding-info-text {
	padding-left: 35px;
	color: #424242;
	font-size: 12px;
	list-style: disc;
}

.ul-padding-info-text li{
	padding-top: 3px;
}

h3.warningNote {
	color: #e84a5f;
	font-size: 10px;
	padding-left: 10px;
}

/* Begin - Animate the loading */
.load-wrapp {
    width: 100px;
    height: 60px;
    padding: 0 50px; margin: 0;
    background-color: transparent;
    display: inline-block;
    transform: rotate(180deg)
}

.line {
    display: inline-block;
    width: 13px;
    height: 15px;
    background-color: #e84a5f;
    margin: 0 2px;
    vertical-align: bottom;
}

.load-1 .line:nth-last-child(1) {animation: loadingA 1.5s 1s infinite; background-color: #d65e6e; vertical-align: top;}
.load-1 .line:nth-last-child(2) {animation: loadingA 1.5s .5s infinite; vertical-align: top;}
.load-1 .line:nth-last-child(3) {animation: loadingA 1.5s 0s infinite; background-color: #e32759; vertical-align: top;}

.wrapperPort {
	background: url(images/bg2Port.png) no-repeat bottom left;
	background-size: contain;
}

.wrapperPort * , .wrapperPort *:before, .wrapperPort *:after {
  box-sizing:border-box;
  -moz-box-sizing:border-box;
  -webkit-box-sizing:border-box;
  -ms-box-sizing:border-box;
}

.splitPort{
	background: url(images/bg1Port.png) no-repeat top right;
	background-size: 70vw;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 2rem;
	font-family: 'Lato', sans-serif;
}

.colPort2 {
	width: 65vw;
	min-height: calc(100vh - 210px);
	min-width: 320px;
	display: grid;
	justify-content: center;
  grid-gap: 10px;
  grid-template-columns: [col1-start] auto [col2-start] auto [col2-end];
  grid-template-rows: [row1-start] auto [row2-start] auto [row2-end];
}

.portCard {
  width: 300px;
  min-height: 220px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 15px;
}

.portcard:hover {
  box-shadow: 0 7px 14px rgba(0, 0, 0, 0.25), 0 5px 7px rgba(0, 0, 0, 0.22);
}

.portCard .button-container {
  display: flex;
  flex-direction: row-reverse;
}

.portCard .button {
  height: 30px;
  width: 30px;
  text-align: center;
  cursor: pointer;
}

.portCard .button:hover {
  background-color: #e6c6ca;
}

.pcTitle {
	display: block;
	border-bottom: 1px solid #2A363B;
	text-transform: uppercase;
	color: #2A363B;
	font-weight: 700;
	font-size: 1.3rem;
	padding-bottom: 10px;
	margin-bottom: 5px;
}

.pcDate {
	color: #107E7C;
	font-size: 1.3rem;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 300;
	margin-bottom: 10px;
}

.pcText {
	font-size: 1rem;
  font-weight: 400;
  padding-left: 5px;
  margin-bottom: 10px;
  color: #707070;
}

.rowPort1 {
  grid-column: col2-start;
  grid-row: row1-start;
  align-self: end;
}

.rowPort2 {
  grid-column: col2-start;
  grid-row: row2-start;
  align-self: start;
}

.rowPort3 {
  grid-column: col1-start;
  grid-row: row1-start / row2-end;
  align-self: center;
}

.colPort1 {
	padding-left: 2rem;
	width: 30vw;
}

.portTitle {
	font-size: 5rem;
	color: #22231C;
	padding-bottom: 20px;
	border-bottom: 2px solid #22231C;
	font-weight: 700;
	margin-bottom: 20px;
	display: inline-block;
}

.portText1 {
	display: grid;
	color: #606060;
	font-size: 0.8rem;
	font-weight: 400;
	grid-template-columns: 1fr 1fr;
	grid-gap: 3rem;
}

.downBtnSpecial {
	display: inline-block;
	vertical-align: top;
}

.downSpecial {
	vertical-align: top;
	padding: 15px 0 0 5px;
	color: #bbbbbb;
  cursor: pointer;
}

.downSpecial2 {
	vertical-align: top;
	padding: 2px 7px 0 7px;
	background-color: #fff;
	margin: -13px 0 0 5px;
	color: #bbbbbb;
  cursor: pointer;
}

.downSpecial3{
	margin-left: 35px;
	margin-top: -2px;
	position: absolute;
}

@keyframes loadingA {
    0% {height: 15px;}
    50% {height: 35px;}
    100% {height: 15px;}
}
/* End - Animate the loading */

/* Media Queries */
@media screen and (max-width: 1400px){

	.portText1 {
		grid-template-columns: 1fr;
		grid-gap: 1rem;
	}
	.colPort2 {
	width: 50vw;
	}
	.colPort1 {
		width: 40vw;
	}

}

@media screen and (max-width: 1200px){

    .btnhome b {
        display: none;
        padding: 30px 20px;
    }

    menu {
        margin: 35px 20px;
    }

    menu button {
        width: 220px;
        padding: 25px 20px;
        font-size: 13px;
    }

    menu button b {
        display: none;
    }

    menu button.active {
        padding: 35px 20px;
        font-size: 22px;
    }

    .content {
        width: 600px;
    }
    .colPort2 {
		width: 52vw;
	  }
    .colPort1 {
		width: 35vw;
	  }
	  .portTitle {
	  	font-size: 4rem;
	  }
}


@media screen and (max-width: 980px){
 	.portCard {
	  width: auto;
	}
	.pcText {
		font-size: 0.9rem
	}

	.leaflet-container {
		padding: 0;
	}

	/* .popbtns {
		display: block;
	}

	.backgraph, .downgraph, .saveStrategyButton, .iconsave {
		width: 166px !important;
	}
	.downgraph {
		margin-top: 5px !important;
	}
	.backgraph {
		margin-right: 5px;
	} */

	.iconsave {
		margin-right: 5px;
	}

	.viewfinder {
	  -webkit-flex: 0 1 0%;
	          flex: 0 1 0%;
	}

	.contentView {
	  -webkit-flex: 1 0 100%;
	          flex: 1 0 100%;
	}

	.viewfinderShow {/*  TODO: Implementing resizing button to apply these 2 styles */
	  -webkit-flex: 1 0 100%;
	          flex: 1 0 100%;
	}

	.contentViewHide {
	  -webkit-flex: 0 1 0%;
	          flex: 0 1 0%;
	}
}

@media screen and (max-width: 910px){
	.colPort2 {
	  grid-template-columns: 1fr;
	  grid-template-rows: [row1-start] auto [row2-start] auto [row3-start] auto [row3-end];
	}
	.rowPort1 {
	  grid-row: row1-start;
	  align-self: center;
	}

	.rowPort2 {
	  grid-row: row2-start;
	  align-self: center;
	}

	.rowPort3 {
	  grid-row: row3-start;
	}
  .portTitle {
  	font-size: 3rem;
  }

    .menuline {
        display: block;
    }

    menu {
        display: block;
    }

    menu button, menu button.active {
        display: inline-block;
        width: 25%;
        margin: 0;
        padding: 0;
        padding: 0 15px;
        height: 100px;
        border: 0;
        border-right: 1px solid #e6e6e6;
        font-size: 15px;
        vertical-align: top;
    }

    menu button.active {
        font-size: 18px;
    }

    .content {
        width: 90%;
        margin-top: 20px;
    }

    .content h1 {
        color: #e84a5f;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 780px){
	.wrapperPort {
		background: url(images/bg4Port.png) no-repeat top right;
	}
}

@media screen and (max-width: 680px){
	.splitPort {
		background: url(images/bg3Port.png) no-repeat bottom left;
		background-size: 70vw;
		display: inline-block;
	}
	.colPort2 {
	width: auto;
	}
	.colPort1 {
	width: auto;
	margin-bottom: 50px;
	}
}

@media screen and (max-width: 619px){
    menu, .content {
        display: none;
    }

    .userInfo h6 {
    	display: none;
    }

}

@media screen and (max-width: 490px){

    .container {
        width: 90%;
    }
}

@media screen and (max-width: 450px){

    .logoGEB {
    	width: 65px;
    	-webkit-background-size: contain;
    	background-size: contain;
    }

}
